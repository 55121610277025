import React from "react"

import "../components/privacypolicy.css"
import Footer from "../components/footer"
import Header2 from "../components/header2"
import Seo from "../components/seo"

const TermsAndConditions = () => {
  return (
    <>
      <Seo title="Terms and Conditions" />
      <Header2 />

      <div className="container mt-5 pt-5">
        <div>
          <h1 className="font-weight-bolder">WEBSITE DISCLAIMER</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              The information provided by Superior Merchants ("we," "us," or
              "our") on SuperiorMerchants.com (the "Site") is for general
              informational purposes only. All information on the Site is
              provided in good faith, however we make no representation or
              warranty of any kind, express or implied, regarding the accuracy,
              adequacy, validity, reliability, availability, or completeness of
              any information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE
              ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
              AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION
              PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON
              ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
            </p>
          </div>
        </div>

        <div>
          <h1 className="font-weight-bolder">PROFESSIONAL DISCLAIMER</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              The Site cannot and does not contain Personal or Business advice.
              The Personal or Business information is provided for general
              informational and educational purposes only and is not a
              substitute for professional advice. Accordingly, before taking any
              actions based upon such information, we encourage you to consult
              with the appropriate professionals. We do not provide any kind of
              Personal or Business advice. THE USE OR RELIANCE OF ANY
              INFORMATION CONTAINED ON THE SITE IS SOLELY AT YOUR OWN RISK.
            </p>
          </div>
        </div>

        <div>
          <h1 className="font-weight-bolder">TCPA DISCLAIMER</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              Notwithstanding any current or prior election to opt in or opt out
              of receiving telemarketing calls, or SMS messages (text messages)
              from our representatives, agents, affiliates, you expressly
              consent to be contacted by SUPERIOR MERCHANTS, our agents,
              representatives, affiliates, or anyone calling on our behalf. This
              is for any purposes relating to your request for Personal or
              Business service, at any telephone number, or physical or
              electronic address you provide or at which you may be reached. You
              agree we may contact you in any way, including SMS messages (text
              messages), calls using prerecorded messages or artificial voice,
              and calls and messages delivered using auto telephone dialing
              system or an automatic texting system.
            </p>
            <p>
              Automated messages may be played when the telephone is answered
              whether by you or someone else. If an agent or representative of
              SUPERIOR MERCHANTS calls, he or she may also leave a message on
              your answering machine, voice mail, or send one via text. You
              certify that the telephone numbers that you have provided, are in
              fact your contact numbers, and you agree to receive calls at each
              phone number you have provided SUPERIOR MERCHANTS. You agree to
              promptly alert SUPERIOR MERCHANTS if you stop using a particular
              phone number. Your cell/mobile telephone provider will charge you
              according to the type of account you carry with those companies.
              You may opt out of any future contact via text message by replying
              anytime with “STOP”. You agree that SUPERIOR MERCHANTS may contact
              you by email, using any email address you have provided or that
              you provide in the future. You may opt out of any future contact
              via email message by replying anytime with “UNSUBSCRIBE”. SUPERIOR
              MERCHANTS may listen to and/or record calls between you and any
              representative without notice, as permitted by applicable laws.
              For example, we listen to calls to monitor for quality purposes.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default TermsAndConditions
